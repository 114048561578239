import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
export const APIClient = {
  auth: async (data) => {
    try {
      const resp = await axios.post(`${API_URL}/user/auth`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (resp.status === 200) {
        return resp.data;
      } else {
        throw new Error(`Unexpected response status: ${resp.status}`);
      }
    } catch (error) {
      console.error("Authentication error:", error);
      throw error;
    }
  },
  tap: async (data) => {
    try {
      const resp = await axios.post(`${API_URL}/user/tap`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (resp.status === 200) {
        return resp.data;
      } else {
        throw new Error(`Unexpected response status: ${resp.status}`);
      }
    } catch (error) {
      console.error("Authentication error:", error);
      throw error;
    }
  },
};
