import { Box, Button, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import WebApp from "telegram-mini-app";
import { APIClient } from "../../api";
const TelegramUserData = () => {
  const [userData, setUserData] = useState(null);
  const [platform, setPlatform] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [currentPoint, setCurrentPoint] = useState(0);

  useEffect(() => {
    if (window?.Telegram?.WebView?.initParams?.tgWebAppData) {
      const userdata = window?.Telegram?.WebView?.initParams?.tgWebAppData;
      setUserToken(userdata);
      setPlatform(window?.Telegram?.WebView?.initParams?.tgWebAppPlatform);
    }
  }, []);

  WebApp.setBackgroundColor("#FFF");

  return (
    <Box w="100%" h="100%" overflow="hidden">
      {userData && (
        <>
          <h1>User Data</h1>
          <p>username: {userData?.username}</p>
          <p>
            name: {userData?.first_name} {userData?.last_name}
          </p>
          <p>id: {userData?.id}</p>
          <p>Is Premium: {userData?.is_premium ? "Yes" : "No"}</p>
          {/* <p>auth Date: {userData?.auth_date}</p> */}
          {/* <p>Hash: {userData?.hash}</p> */}
          <p>Platform: {platform}</p>
        </>
      )}

      {userToken}
      {userData ? (
        <Box>
          <Text>Current point: {currentPoint}</Text>
          <Button
            w="100px"
            h="100px"
            onClick={async () => {
              const data = await APIClient.tap({ userToken });
              if (data?.point) setCurrentPoint(data?.point);
            }}
          >
            TAP
          </Button>
        </Box>
      ) : (
        <Button
          onClick={() => {
            (async () => {
              if (userToken) {
                const authInfo = await APIClient.auth({ userToken });
                setUserData(authInfo?.data);
                setCurrentPoint(authInfo?.data?.point);
              }
            })();
          }}
          isDisabled={!userToken}
        >
          Start
        </Button>
      )}
    </Box>
  );
};

export default TelegramUserData;
