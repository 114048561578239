import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import HomePage from "./pages/home";

function App() {
  return (
    <HashRouter>
      {/* <DefaultLayout> */}
      <Switch>
        {/* <Redirect exact from="/" to={`/home`} /> */}
        <Route exact path={`/`}>
          <HomePage />
        </Route>
      </Switch>
      {/* </DefaultLayout> */}
    </HashRouter>
  );
}

export default App;
